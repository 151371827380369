import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {datesService} from "../../services/DatesService";
import ListingListItem from './ListingListItem';
import {
    useFloating,
    autoUpdate,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    FloatingPortal
} from "@floating-ui/react";

function ListingEventCell(props) {
    const {getListingTime} = datesService();

    const defaultClass = props.children?.props.className || `rbc-event rbc-event-allday rbc-event-continues-after`
    const customClass = `${defaultClass} rbc-event--${props.type}`;
    const listing = props.listing;
    const inModal = props.inModal;
    
    // Floating UI
    const [isOpen, setIsOpen] = useState(false);
    const [isMouse, setIsMouse] = useState(true);
    const [xOffset, setOffset] = useState(0);
    
    // Adjust Popover on Events with More than One Day
    //if mouse click set position to cursor
    function checkCursorPosition(event){
        const clickX = event.clientX;
        setOffset(clickX);
    }
    // if keyboard down, position x is 0
    function keyboardPopover(e) {
        setIsMouse(false);
    }

    // Custom Offset
    const shiftByAmount = (amount = 0) => ({
        name: 'shiftByAmount',
        options: amount,
        fn: ({x}) => ({
            x: 
                isMouse && listing.isMoreThenOneDay && !inModal ?
                    amount - (floatingEl.current.offsetWidth / 2)
                : 
                    x,
        }),
    });
    
    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            shiftByAmount(xOffset), 
            flip(),
            shift()
        ],
        whileElementsMounted: autoUpdate
    });
    
    const floatingEl = refs.floating;
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role
    ]);
    

    function getParentClass() {
        let className = '';

        if (listing.isMoreThenOneDay && listing.isSoldOut) {
            className += 'sold-out-bar';
        } else if (listing.isMoreThenOneDay) {
            className += 'has-bar';
        } else {
            className += 'no-bar';
        }

        return className + (listing.isPastEvent ? ' past' : ' upcoming');
    }

    return (
        <>
            <button 
                className={`event ${getParentClass()} ${customClass}`} 
                ref={refs.setReference} 
                {...getReferenceProps({
                    onClick(event) {
                        checkCursorPosition(event);
                    },
                })}
                onKeyDown={keyboardPopover}
            >
                <div className="time">
                    {listing.multipleTimes ? 'Multiple Times' : getListingTime(listing.start)}
                </div>

                <div className="description">
                    <div className="title">
                        <span className="event-description__title__text">{listing.title}</span>
                    </div>
                    { listing.isSoldOut && <span className="sold-out-label">Sold out</span> }
                </div>

            </button>
            {isOpen && (
                <FloatingPortal id="portal-popup">
                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            ref={refs.setFloating}
                            style={floatingStyles}
                            aria-labelledby=""
                            {...getFloatingProps()}
                        >
                            <div 
                                className='listing-event-modal' 
                            >
                                <button
                                className="button button--default button--round listing-event-modal__close"
                                onClick={() => {setIsOpen(false);}}
                                >
                                    <i className="icon-close" aria-hidden="true"></i>
                                    <span className="sr-only">Close Event Popover</span>
                                </button>
                                <ListingListItem listing={listing}/>
                            </div>
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );
}

ListingEventCell.propTypes = {
    listing: PropTypes.object.isRequired,
    type: PropTypes.string,
}

ListingEventCell.defaultProps = {
    type: '',
}

export default ListingEventCell;
