import React from 'react';
import Icon from "./Icon";
import PropTypes from "prop-types";

const TwitterIcon = (props) => {
    return (
        <div onClick={props.onClick}>
            <Icon
                className='primary-color circle bg-white'
                icon='icon-twitter'
                selected={props.selected}
                blockSize='36px'
            />
        </div>
    );
}

TwitterIcon.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
}

TwitterIcon.defaultProps = {
    selected: false,
    onClick: () => {}
}

export default TwitterIcon;
