import React from 'react';
import PropTypes from 'prop-types';

const TextField = (props) => {
    const getPadding = () => {
        const padding = { top: 15, bottom: 15, left: 12, right: 13};

        if(props.icon) {
            padding.left += 35;
        }

        return `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`;
    }

    return (
        <div>
            {props.icon && <div className={props.icon + ' input-icon'} />}
            <input
                style={{ padding: getPadding() }}
                id={props.id}
                type={props.type ? props.type : 'text'}
                className={'input-group__text ' + (props.icon ? 'has-icon' : '')}
                defaultValue={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
        </div>
    );
}

TextField.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
}

TextField.defaultProps = {
    value: '',
    icon: '',
    type: '',
    id: '',
    onChange: () => {},
    placeholder: '',
}

export default TextField;
