export function httpService() {

    const get = (url) => {
        return fetch(url, {
            method: "GET",
        })
    }

    const buildQueryParams = (params) => {
        const paramsToBuild = [];

        for(const key in params) {
            if(params[key]) {
                paramsToBuild.push(`${key}=${urlEncode(params[key])}`)
            }
        }

        const queryParams = paramsToBuild.join('&');

        return queryParams.length ? '?' + queryParams : '';
    }

    const urlEncode = (str) => {
        return encodeURIComponent(str);
    }

    return {
        get,
        buildQueryParams,
        urlEncode
    }
}
