import React from "react";
import ListingListItem from './ListingListItem';

const ListingMobileList = props => {
    return (
        <div className={'mobile-listings-list'} ref={props.mobileListingViewReference}>
            <h2 className="current-date">{props.clickedDate}</h2>
            {props.listings.map(
                (listing, index) => <ListingListItem listing={listing} key={index} />
            )}
        </div>
    )
}

export default ListingMobileList;
