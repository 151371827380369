import { isSameDay as SharedIsSameDay } from '../../../shared/util/dates'
import moment from 'moment-timezone';

let locale = 'en-US';

export function datesService() {
    const useLocale = (loc) => {
        locale = loc;
    }

    const Timestamp = (timestamp) => {
        return parseInt(timestamp) * 1000;
    }

    const isTimestamp = (timestamp) => {
        return new Date(timestamp ? Timestamp(timestamp) : timestamp).getTime() > 0;
    }

    const toTimestamp = (date) => Date.parse(date) / 1000;

    const isDateString = (dateStr) => {
        return !isNaN(new Date(dateStr).getTime());
    }

    const isLaterThanNow = (date) => {
        return date.getTime() > new Date().getTime();
    }

    const serverDateToBrowserDate = (serverDateString, serverTimezoneName) => {
        return moment.tz(serverDateString, serverTimezoneName).toDate()
    }

    const getFormattedDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = { month: 'long', day: 'numeric', year: 'numeric', weekday: 'long' };
        return new Intl.DateTimeFormat(locale, options).format(date);
    }

    const getListingDate = (dateStr) => {
        const date = new Date(dateStr);
        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };

        return new Intl.DateTimeFormat(locale, options).format(date);
    }

    const getListingTime = (dateStr) => {
        const date = new Date(dateStr);
        const options = { hour: 'numeric', minute: 'numeric', hour12: true };

        return new Intl.DateTimeFormat(locale, options).format(date);
    }

    const getMobileListingDay = (dateStr) => {
        const date = new Date(dateStr);
        const options = { month: 'short', day: 'numeric', year: 'numeric' };

        return new Intl.DateTimeFormat(locale, options).format(date);
    }

    const isSameDay = (date1, date2) => {
        return SharedIsSameDay(date1, date2);
    }

    const isBetween = (baseDate, date1, date2) => baseDate.getTime() >= date1.getTime() && baseDate.getTime() <= date2.getTime();

    const toolbarDefaultDateFormat = date => {
        return `${date.toLocaleString(locale, { month: 'long' })} ${date.getFullYear()}`
    }

    const dateYMD = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    const dateYMDHIS = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const getFirstAndLastDayOfMonth = (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

        return {
            firstDay,
            lastDay,
        }
    }

    const getPreviousMonthDate = (date) => {
        if(isDateString(date)) {
            date = new Date(date);
        }

        return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }

    const getNextMonthDate = (date) => {
        if(isDateString(date)) {
            date = new Date(date);
        }

        return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
    }

    const isPastDay = (date) => {
        const day = new Date(date);
        day.setHours(0);
        day.setMinutes(0);
        day.setSeconds(0);

        return day.getTime() < new Date().getTime();
    }

    const withTimezoneOffset = (date) => {
        const userTimezoneOffsetDiff = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffsetDiff);
    }

    return {
        isTimestamp,
        toTimestamp,
        isDateString,
        isSameDay,
        getFormattedDate,
        getListingDate,
        getListingTime,
        getMobileListingDay,
        isBetween,
        toolbarDefaultDateFormat,
        useLocale,
        isLaterThanNow,
        serverDateToBrowserDate,
        dateYMD,
        dateYMDHIS,
        getFirstAndLastDayOfMonth,
        getPreviousMonthDate,
        withTimezoneOffset,
        getNextMonthDate,
        isPastDay,
    }
}
