import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { datesService } from '../../services/DatesService';
import Button from '@showclix-shared/Button';
import { ListingListItemImage } from '@showclix-shared/ListingComponents';
import { AppContext } from '../../App';

const ListingListItem = props => {
  const { t, serverTimezoneName } = useContext(AppContext);
  const {
    getListingDate,
    getListingTime,
    isDateString,
    isSameDay,
    isLaterThanNow,
    toTimestamp,
    serverDateToBrowserDate
  } = datesService();

  const getImage = listing => {
    if (listing.image !== '') {
      return <ListingListItemImage src={listing.image} alt={listing.listing_title} />;
    } else {
      return (
        <div className="listing-item__image listing-item__image--empty">
          <i className="icon-calendar" aria-hidden="true" />
        </div>
      );
    }
  };

  const getCheckoutLink = listing => '/tickets/' + listing.listing_slug;

  const getBuyTicketsLink = listing => getCheckoutLink(listing) + '?date=' + toTimestamp(listing.event_start);

  const getDate = listing => {
    if (parseInt(listing.children_count) > 0 && !listing.single_event_series) {
      return t('org_listing__multiple_dates_and_times');
    }

    if (listing.multipleTimes) {
      return 'Multiple Times';
    }

    if (isDateString(listing.event_end)) {
      return isSameDay(new Date(listing.event_start), new Date(listing.event_end))
        ? getListingDate(listing.event_start) + ' - ' + getListingTime(listing.event_end)
        : getListingDate(listing.event_start) + ' - ' + getListingDate(listing.event_end);
    }

    return getListingDate(listing.event_start);
  };

  const getVenueInfo = listing => {
    return listing.venue_name + ' ' + t('org_listing__in') + ' ' + listing.venue_city;
  };

  const getMinPrice = listing => {
    if (listing.has_only_free_tickets) {
        return t('org_listing__free_entry');
    }

    if (!isNaN(listing.min_price) && listing.min_price_fmt) {
        return t('org_listing__start_at') + ' ' + listing.min_price_fmt;
    }

    return '';
  };

  const renderButton = listing => {
    const end = serverDateToBrowserDate(listing.server_event_end, serverTimezoneName);
    if (!isLaterThanNow(end)) {
      return <></>;
    }

    if (listing.sold_out) {
      return (
        <Button className="button--listing-item-cta button--default" variant={Button.variant.primary} disabled>
          <span className="sr-only">{props.listing.listing_title} is </span>
          {t('org_listing__sold_out')}
        </Button>
      );
    }

    return (
      <a
        className="button--listing-item-cta button--primary button--link"
        href={getBuyTicketsLink(props.listing)}
        target="_blank"
      >
        {props.listing.custom_button_text ?? t('org_listing__buy_tickets')}{' '}
        <span className="sr-only">for {props.listing.listing_title}</span>
      </a>
    );
  };

  return (
    <div className="listing-item">
      <div className="listing-item__hero">{getImage(props.listing)}</div>

      <div className="listing-item__content">
        <div>
          <div className="listing-item__header">
            {props.decodeTitle && <h3 dangerouslySetInnerHTML={{ __html: props.listing.listing_title }} />}
            {!props.decodeTitle && <h3>{props.listing.listing_title}</h3>}
          </div>

          <div className="listing-item__description">
            <p>{getDate(props.listing)}</p>
            <p>{getVenueInfo(props.listing)}</p>
            <p>{getMinPrice(props.listing)}</p>
          </div>
        </div>

        <div className="listing-item__buttons">
          <a
            className="button--listing-item-cta mb-2 button--clear button--link"
            href={getCheckoutLink(props.listing)}
            target="_blank"
          >
            {t('org_listing__event_details')} <span className="sr-only">for {props.listing.listing_title}</span>
          </a>
          {renderButton(props.listing)}
        </div>
      </div>
    </div>
  );
};

ListingListItem.propTypes = {
  listing: PropTypes.object,
  decodeTitle: PropTypes.bool
};

export const ListingListItemSkeleton = () => {
  return (
    <div className="listing-item">
      <div className="listing-item__hero">
        <div className="listing-item__image listing-item__image--empty">
          <i className="icon-calendar" aria-hidden="true" />
        </div>
      </div>

      <div className="listing-item__content listing-item__content--loading">
        <div>
          <div className="listing-item__header">
            <h3>&nbsp;</h3>
          </div>

          <div className="listing-item__description">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>

        <div className="listing-item__buttons">
          <Button className="button--listing-item-cta mb-2">&nbsp;</Button>
          <Button className="button--listing-item-cta mb-2">&nbsp;</Button>
        </div>
      </div>
    </div>
  );
};

export default ListingListItem;
