import React from 'react';
import Icon from "./Icon";
import PropTypes from "prop-types";

const GridViewIcon = (props) => {
    const getClassName = () => {
        let className = props.className + ' ' + 'button button--clear button--square';

        if(props.selected) {
            className += ' selected';
        }

        return className;
    }
    return (
        <button 
            className={getClassName()} 
            onClick={props.onClick}
        >
            <span className="sr-only">Display Events in a Grid Layout</span>
            <Icon
                icon='icon-grid_view'
            />
        </button>
    );
}

GridViewIcon.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
}

GridViewIcon.defaultProps = {
    selected: false,
    onClick: () => {}
}

export default GridViewIcon;
