/**
 * Get the YYYY-MM-DD representation of a javascript date object
 * @param  Date
 * @return string
 */
export const getYYYYMMDD = (date) => {
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const monthString = month.length < 2 ? `0${month}` : month;
  const dayString = day.length < 2 ? `0${day}` : day;
  return `${date.getFullYear()}-${monthString}-${dayString}`;
};

export const getYYYYMM = (date) => {
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const monthString = month.length < 2 ? `0${month}` : month;
  return `${date.getFullYear()}-${monthString}`;
};

/**
 * Get javascript date from YYYY-MM-DD string
 * returns current date if error parsing string
 * @param  String
 * @return Date
 */
export const fromYYYYMMDD = (dateString) => {
  if (typeof dateString !== 'string') {
    return new Date();
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);
  const date = new Date(+year, +month - 1, +day);
  return isNaN(date.getTime()) ? new Date() : date;
};

/**
 * Get H:i:s time string from date object
 * @param  Date
 * @return string
 */
export const getHoursMinutesSeconds = (date) => {
  return date.toISOString().slice(0, 19).split('T')[1];
};

/**
 * Get Y-m-d H:i:s datetime string from date object
 * @param  Date
 * @return {string
 */
export const getDateTimeString = (date) => {
  return `${getYYYYMMDD(date)} ${getHoursMinutesSeconds(date)}`;
};
/**
 * Create a copy of the provided date
 * @param  Date
 * @return Date
 */
export const cloneDate = (date) => {
  return new Date(date.getTime());
};

/**
 * Is the provided a valid timezone string that can be used with the Intl API
 *
 * @param  string
 * @return bool
 */
export const isValidTimezoneString = (tz) => {
  const aDate = new Date();
  try {
    if (typeof aDate.toLocaleString([], { timezone: tz }) === 'string') {
      return true;
    }
  } catch (err) {
    return false;
  }
};

/**
 * Add/subtract days to provided date
 * @param  Date
 * @param  number
 * @return Date
 */
export const applyDaysToDate = (date, days) => {
  const newDate = cloneDate(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

/**
 * Get the last day of week prior to the provided date. Example: "The previous tuesday before..."
 * @param  Date         referenceDate
 * @param  int | string day - the numeric representation of the day of week, or the case insensitive day string
 *                            ex. getPreviousDayOfWeekFromDate(date, 2) === getPreviousDayOfWeekFromDate(date, 'Tuesday')
 * @return date
 */
export const getPreviousDayOfWeekFromDate = (referenceDate, day) => {
  const map = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  const requestedDayString = String(day).toLowerCase();

  // if invalid day arg provided, just return the provided reference date
  if (Object.keys(map).indexOf(requestedDayString) === -1) {
    return referenceDate;
  }

  const numericDayOfWeek = map[requestedDayString];
  const currentDay = referenceDate.getDay();
  const diff = currentDay - numericDayOfWeek;
  let toSubtract = 7;
  if (currentDay !== numericDayOfWeek) {
    toSubtract = currentDay > numericDayOfWeek ? diff : diff + 7;
  }
  return applyDaysToDate(cloneDate(referenceDate), toSubtract * -1);
};

/**
 * Returns a date object from the provided ISO date string.
 * @param  string
 * @return Date
 */
export function ISOStringToDate(string) {
  const parts = string.split(/\D/);
  return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
}

/**
 * Handle creating date object from date string
 * @param  string
 * @return Date
 */
export const stringToDate = (string) => {
  let result = new Date(string);
  if (isNaN(result.getTime())) {
    // handle safari
    result = new Date(string.replace(/-/g, '/'));
  }
  return result;
};

/**
 * Compare two dates on year month and day
 * @return boolean
 * @param Date
 * @param Date
 */
export function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const setHoursMinutesOnDate = (target, hours, minutes) => {
  target.setHours(Number(hours));
  target.setMinutes(Number(minutes));
  target.setSeconds(0);
  target.setMilliseconds(0);
};

export const updateDateInDate = (prev, next) => {
  const timeString = prev.toTimeString();
  const [hours, minutes] = timeString.split(':');
  const newDate = new Date(next);
  setHoursMinutesOnDate(newDate, hours, minutes);
  return newDate;
};

export const updateTimeInDate = (prev, next) => {
  const newDate = new Date(prev);
  const [hours, minutes] = next.split(':');
  setHoursMinutesOnDate(newDate, hours, minutes);
  return newDate;
};

export const getHHMMFromISOString = (iso) => {
  if (!iso) return undefined;
  const [hours, minutes] = new Date(iso).toTimeString().split(':');
  return `${hours}:${minutes}`;
};

/**
 * @description take an ISO string and format in a way to have Server date without timezone.
 * Useful for calendars which outputs date in ISO format.
 * @example '2023-08-21T10:00:00.000Z' in UTC+3 converts to: '2023-08-21 13:00:00'
 * @param {string} ISOString
 * @returns {string}
 */
export const formatISOStringToServerDateString = (ISOString) => {
  const date = new Date(ISOString);
  const userTimezoneOffsetDiff = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffsetDiff).toISOString().replace('T', ' ').split('.')[0];
};

/**
 * @param {Date} date
 * @returns {{last: Date, first: Date}}
 */
export const getFirstAndLastDayOfMonth = (date) => {
  const fullYear = date.getFullYear();
  const month = date.getMonth();

  const first = new Date(fullYear, month, 1);

  const last = new Date(fullYear, month + 1, 0);
  last.setHours(23, 59, 59, 999);

  return {
    first,
    last,
  };
};
