import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import TextField from "../utils/fields/TextField";
import {AppContext} from "../../App";

const ListingSearch = (props) => {
    const { t } = useContext(AppContext)

    return (
        <div className={props.className}>
            <label className="sr-only" htmlFor="search">Search events:</label>
            <TextField
                icon='icon-search'
                onChange={props.onChange}
                type="search"
                id="search"
                placeholder={t('org_listing__search')}
            />
        </div>
    );
}

ListingSearch.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
}

ListingSearch.defaultProps = {
    value: '',
    className: '',
    onChange: () => {}
}

export default ListingSearch;
