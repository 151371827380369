import React, { Component } from 'react';
import PropTypes from 'prop-types';

const variant = {
  default: 'button--default',
  primary: 'button--primary',
  white: 'button--white',
  link: 'button--link',
  clear: 'button--clear',
  plain: 'button--plain',
  destructive: 'button--destructive',
  round: 'button--round',
  pill: 'button--pill',
  toggle: 'button--toggle',
};

const size = {
  small: 'button--small',
  medium: 'button--medium',
  large: 'button--large',
};

class Button extends Component {
  render() {
    return (
      <button
        type={this.props.type}
        onClick={this.props.onClick}
        className={`button ${this.props.size} ${this.props.variant} ${this.props.className}`}
        disabled={this.props.disabled || this.props.loading}
      >
        {this.getContent()}
      </button>
    );
  }

  getContent = () => {
    if (this.props.loading) {
      return <ButtonLoading>{this.props.loadingText}</ButtonLoading>;
    } else {
      return this.props.children;
    }
  };
}

class ButtonLoading extends Component {
  render() {
    return (
      <span>
        <span className="loader"></span>
        {this.props.children}
      </span>
    );
  }
}

Button.variant = variant;
Button.size = size;

// https://www.npmjs.com/package/prop-types

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingText: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(variant)),
  size: PropTypes.oneOf(Object.values(size)),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  loadingText: 'Loading...',
  variant: Button.variant.default,
  size: Button.size.medium,
};

export default Button;
