import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
    const getClassName = () => {
        let className = props.className + ' ' + props.icon + ' icon';

        return className;
    }

    return (
        <i
            onClick={props.onClick}
            className={getClassName()}
            style={{ 
                fontSize: props.size 
            }}
            aria-hidden="true"
        />
    );

}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
    onClick: PropTypes.func,
}

Icon.defaultProps = {
    onClick: () => {},
    className: '',
    size: '24px',
}

export default Icon;
