import React from "react";

export default function ErrorMessage(props) {

    return (
        !!props.errorMessage && (
            <div className="message message__inline-banner message--error">
                <span className="message__icon">
                </span>
                <span className="message-text">{props.errorMessage}</span>
            </div>
        )
    )
}
