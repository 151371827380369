import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import 'moment/min/locales';

export default class EventsCalendar extends Component {

    weekStartDay = () => {
        return this.props.weekStartDay === 1 ? 1 : 0;
    }

    getMomentLocale = () => {
        return this.props.locale.slice(0, 2).toLocaleLowerCase();
    }

    render() {

        const weekStartDay = this.weekStartDay();

        // https://momentjs.com/docs/#/customization/dow-doy/
        moment.locale(this.getMomentLocale(), {
            week: {
                dow: weekStartDay,
                doy: 7 + weekStartDay - 1,
            },
        });

        const localizer = momentLocalizer(moment);

        let props = {...this.props};
        delete props.style; // handled separately below
        return (
            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                titleAccessor="name"
                toolbar={this.props.components ? (!!this.props.components.toolbar) : false}
                style={{...this.props.style}}
                onNavigate={() => {} }/* empty to stop some notices, it'll get overridden below if you pass it in*/
                {...props}
            />
        );
    }
}

EventsCalendar.propTypes = {
    locale: PropTypes.string,
    events: PropTypes.array.isRequired,
    components: PropTypes.object,
    style: PropTypes.object,
    defaultView: PropTypes.string,
    defaultDate: PropTypes.string,
    weekStartDay: PropTypes.number
}

EventsCalendar.defaultProps = {
    locale: 'en-US',
    style: {},
    defaultView: "month",
}
