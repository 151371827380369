import { AppContext } from "../../App";
import { debounce } from "../../utils/functions"
import Loader from "../../components/utils/Loader";

import React, {useState, useEffect, useContext, Fragment, useRef} from 'react';

import EventsCalendar from '@showclix-shared/EventsCalendar';
import ListingEventCell from './ListingEventCell';
import ListingToolbar from './ListingToolbar';
import { listingService } from "../../services/ListingService";
import { datesService } from "../../services/DatesService";
import ErrorMessage from '../utils/ErrorMessage';
import ListingEventsModal from './ListingEventsModal';
import ListingMobileList from './ListingMobileList';
import Modal from '@showclix-shared/Modal';
import { stringToDate } from '@showclix-shared-util/dates';

const ListingCalendar = (props) => {
    const today = new Date();
    const weekStartDay = 0; // sunday

    const { locale, serverTimezoneName } = useContext(AppContext);
    const {
        dateYMD,
        toTimestamp,
        isBetween,
        getMobileListingDay,
        getFirstAndLastDayOfMonth,
        isDateString,
        getNextMonthDate,
        getPreviousMonthDate,
        isSameDay,
        isLaterThanNow,
        serverDateToBrowserDate,
        withTimezoneOffset,
        getFormattedDate
    } = datesService();

    const [currentDate, setCurrentDate] = useState(today);
    const [errorMessage, setErrorMessage] = useState(null);
    const [listingsLoaded, setListingsLoaded] = useState(false);
    const [listings, setListings] = useState([]);
    const [showMoreModal, setShowMoreModal] = useState(false);
    const [moreModalDate, setMoreModalDate] = useState(null);
    const [modalListings, setModalListings] = useState([]);
    const [mobileListings, setMobileListings] = useState([]);
    const [clickedDate, setClickedDate] = useState(null);

    const { apiUrl, sellerShortName } = useContext(AppContext);
    const { getRangeListings } = listingService(apiUrl);

    const mobileListingViewReference = useRef(null);

    useEffect(() => {
        void resetListings(getUrlDateParam());

        return () => {
            document.querySelectorAll(".rbc-show-more")?.forEach(link => link.removeEventListener('click', showPopup));
            document.removeEventListener('click', hidePopup);
        };
    }, []);


    if (listingsLoaded && !showMoreModal) {
        setTimeout(() => {
            document.querySelectorAll(".rbc-show-more")?.forEach(link => link.addEventListener('click', showPopup));
        }, 10);
    }

    const showPopup = () => {
        setShowMoreModal(true);
    }

    const hidePopup = () => {
        setShowMoreModal(false);
    }

    // Set the next date to the previous month and fetch data if needed
    const onNextMonth = async () => {
        const nextMonthDate = getNextMonthDate(currentDate);
        await loadListingsForMonth(nextMonthDate);
    }

    // Set the current date to the previous month and fetch data if needed
    const onPreviousMonth = async () => {
        const prevMonthDate = getPreviousMonthDate(currentDate);
        await loadListingsForMonth(prevMonthDate);
    };

    const loadListingsForMonth = async (date) => {
        return debounce(async () => {
            await resetListings(date);
        }, 300);
    }

    const resetListings = async (date) => {
        updateUrlParam(date);
        setCurrentDate(date);

        try {
            setListingsLoaded(false);

            const prevMonth = getPreviousMonthDate(date);
            const nextMonth = getNextMonthDate(date);

            //range of dates will be (first day of previous month - last day of next month)
            const { firstDay } = getFirstAndLastDayOfMonth(prevMonth);
            const { lastDay } = getFirstAndLastDayOfMonth(nextMonth);

            const result = await getRangeListings(
                sellerShortName,
                toTimestamp(firstDay),
                toTimestamp(lastDay)
            );

            setListings(prepareListings(result.listings));
        } catch (e) {
            setErrorMessage(e.message);
        } finally {
            setListingsLoaded(true);
        }
    }

    const getUrlDateParam = () => {
        const calendarOpenDateString = (new URL(location.href))?.searchParams?.get("month");

        return calendarOpenDateString && isDateString(calendarOpenDateString)
            ? getFirstAndLastDayOfMonth(withTimezoneOffset(new Date(calendarOpenDateString))).firstDay
            : getFirstAndLastDayOfMonth(withTimezoneOffset(today)).firstDay;
    };

    const updateUrlParam = (date) => {
        const url = new URL(window.location);
        const params = new URLSearchParams(url.search);
        const formattedDate = dateYMD(date)

        params.append('month', formattedDate);
        window.history.pushState('thing', 'title', url.origin + url.pathname + '?month=' + formattedDate);
    };

    const getEndDateFromListing = listing => !!listing.event_end ? stringToDate(listing.event_end) : stringToDate(listing.event_start);
    const getStartDateFromListing = listing => stringToDate(listing.event_start);

    /**
     * Group and format listings
     * Groups multiple event times for the same listing on the same day into a single entry
     */
    const prepareListings = (listings) => {

        // group
        const timesByDayByListingId = {};
        listings.forEach(listing => {
            if (!listing?.event_start?.substring) {
                return;
            }
            const day = listing.event_start.substring(0, 10);
            const time = listing.event_start.substring(11, 19);

            if (!day?.length == 10 || !time?.length == 8) {
                return;
            }

            if (!timesByDayByListingId[listing.listing_id]) {
                timesByDayByListingId[listing.listing_id] = {};
            }
            if (!timesByDayByListingId[listing.listing_id][day]) {
                timesByDayByListingId[listing.listing_id][day] = {};
            }
            timesByDayByListingId[listing.listing_id][day][time] = listing;
        });

        // format
        const formatted = [];
        Object.keys(timesByDayByListingId).forEach(listingId => {
            Object.keys(timesByDayByListingId[listingId]).forEach(day => {
                const listingsOnDay = Object.values(timesByDayByListingId[listingId][day]);
                const multipleTimes = listingsOnDay.length > 1;
                const listing = listingsOnDay[0];

                let end = getEndDateFromListing(listing);
                let start = getStartDateFromListing(listing);
                let isSoldOut = listing.sold_out;

                if (multipleTimes) {
                    let allSoldOut = true;
                    listingsOnDay.forEach(listingOnDay => {
                        if (!listingOnDay.sold_out) {
                            allSoldOut = false;
                        }
                        const thisEnd = getEndDateFromListing(listingOnDay);
                        const thisStart = getStartDateFromListing(listingOnDay);
                        if (thisEnd.getTime() > end.getTime()) {
                            end = thisEnd;
                        }
                        if (thisStart.getTime() < start.getTime()) {
                            start = thisStart;
                        }
                    });
                    isSoldOut = allSoldOut;
                }

                formatted.push({
                    ...listing,
                    id: listing.listing_id,
                    image: listing.image,
                    title: listing.listing_title,
                    start,
                    end,
                    isMoreThenOneDay: !isSameDay(start, end),
                    isPastEvent: !isLaterThanNow(serverDateToBrowserDate(listing.server_event_end, serverTimezoneName)),
                    isSoldOut,
                    multipleTimes,
                });
            })
        });

        return formatted;
    }


    const onShowMore = (listings, events) => {
        setShowMoreModal(true);
        setModalListings(listings);
        const modalDate = getFormattedDate(events);
        setMoreModalDate(modalDate);
    }

    const dateClickHandler = (date, listings) => {
        setMobileListings(listings);

        // scroll only if listings available
        if (listings.length) {
            mobileListingViewReference.current.scrollIntoView({
                behavior: 'smooth', block: 'start'
            });

            setClickedDate(getMobileListingDay(new Date(date)));
        } else {
            setClickedDate(null);
        }
    }

    const eventCalendarComponents = (listings) => {
        return {
            eventWrapper: (listing) => <ListingEventCell {...listing} listing={listing.event} inModal={false} />,
            month: {
                dateHeader: ({date, label}) => {
                    const selectedDateClass =
                        clickedDate &&
                        clickedDate ===
                            getMobileListingDay(date)
                            ? "selected-date"
                            : "";
                    const filteredListings = listings.filter(
                        event => isSameDay(date, event.start) || isBetween(date, event.start, event.end)
                    );

                    let circleClass = '';
                    if(filteredListings.length) {
                        circleClass = filteredListings.every((listing) => listing.isSoldOut || listing.isPastEvent)
                            ? 'day-with-inactive-events'
                            : 'day-with-events';
                    }

                    const isPastDate = isLaterThanNow(date) ? 'future-date' : 'past-date';

                    return (
                        <a className={`${circleClass} ${isPastDate} ${selectedDateClass}`} onClick={() => dateClickHandler(date, filteredListings)}>
                            {label}
                        </a>
                    );
                }
            }
        };
    }

    const dayPropGetter = () => {
        return {
            style: { // white background for all cells in the calendar
                backgroundColor: 'var(--background-color-0)'
            }
        }
    }

    return (
        <Fragment>
            <div className="series-calendar-month-view__content">
                <ErrorMessage errorMessage={errorMessage}/>

                <Loader height='350px' loaded={listingsLoaded}>

                    <ListingToolbar
                        onNext={onNextMonth}
                        onPrevious={onPreviousMonth}
                        date={currentDate}
                        weekStartDay={weekStartDay}
                    />

                    <EventsCalendar
                        components={eventCalendarComponents(listings)}
                        defaultView="month"
                        locale={locale}
                        events={listings}
                        date={currentDate}
                        weekStartDay={weekStartDay}
                        startAccessor="start"
                        endAccessor="end"
                        titleAccessor="title"
                        dayPropGetter={dayPropGetter}
                        popup={false}
                        showMultiDayTimes={true}      // event may take a few days (blue line across a few cells)
                        onShowMore={onShowMore}       // +x more button
                        doShowMoreDrillDown={false}   // prevent to show timeslot (day view) on click to "+x more", we have described own behavior
                        formats={{dateFormat: 'D'}} // to avoid leading zero in dates
                        views={['month']}             // to disable timeslots view on click to date
                    />

                    <Modal
                        show={showMoreModal}
                        size={Modal.size.large}
                        onClose={hidePopup}
                        dismissable={true}
                        forceFocus={true}
                        className=""
                        trapFocus={false}
                    >
                        {moreModalDate && <Modal.Header>
                            <Modal.Title>{moreModalDate}</Modal.Title>
                        </Modal.Header>}
                        <Modal.Body>
                            <ListingEventsModal listings={modalListings} />
                        </Modal.Body>
                    </Modal>
                </Loader >
            </div>

            {mobileListings && <ListingMobileList
                listings={mobileListings}
                mobileListingViewReference={mobileListingViewReference}
                clickedDate={clickedDate}
                date={moreModalDate}
            />}
        </Fragment>
    )
}

export default ListingCalendar;
