import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
const FocusTrap = require('focus-trap-react');


const size = {
  small: "modal--small",
  medium: "modal--medium",
  large: "modal--large",
  xlarge: "modal--xlarge",
}

const scroll = {
  internal: "modal-scroll--internal",
  external: "modal-scroll--external",
}

const position = {
  top: "modal-position--top",
  center: "modal-position--center",
}

export class ModalHeader extends Component {
  render(){
    return(
      <div className={`modal__header ${this.props.className ? this.props.className : ''}`}>
        {this.props.children}
      </div>
    )
  }
}
export class ModalTitle extends Component {
  render(){
    return(
      <div
        className="modal__title">
          {this.props.children}
      </div>
    )
  }
}
export class ModalBody extends Component {
  render(){
    const height = {
      flexBasis: `${this.props.height ? this.props.height : null}`
    }
    return(
      <div className={`modal__body ${this.props.className ? this.props.className : ''}`} style={height}>
        {this.props.children}
      </div>
    )
  }
}
export class ModalFooter extends Component {
  render(){
    return(
      <div className={`modal__footer ${this.props.className ? this.props.className : ''}`}>
        {this.props.children}
      </div>
    )
  }
}
export default class Modal extends Component {
  constructor() {
    super();
    this.portalRoot = document.getElementById('portal-root');
    if (this.portalRoot === null) {
      this.portalRoot = document.createElement('div');
      this.portalRoot.id = 'portal-root';
    }
    this.el = document.createElement('div');
    this.wrapperRef = React.createRef();
    this.state = {isOpen: false};

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleOutsideClick = (event) => {
    if ((event.target === this.wrapperRef.current) && this.props.dismissableOnBlur && this.props.onClose){
      this.props.onClose();
    }
  }

  componentDidMount = () => {
    this.portalRoot.appendChild(this.el);
  }

  componentWillUnmount = () => {
    this.portalRoot.removeChild(this.el);
  }

  static Header = ModalHeader;
  static Title = ModalTitle;
  static Body = ModalBody;
  static Footer = ModalFooter;
  modalRef = null;

  //Focus on modal when open
  componentDidUpdate(){
    if(this.props.forceFocus){
      if(this.props.show && this.modalRef){
        this.modalRef.focus();
      }
    }
  }

  render() {
    //add / remove class to body to stop scrolling behind open modal and set aria-hidden
    if(this.props.show){
      document.body.classList.add('modal--open');
      document.body.setAttribute("aria-hidden", "true");
    }else{
      document.body.classList.remove('modal--open');
      document.body.setAttribute("aria-hidden", "false");
    }

    //conditionally show modal
    if(!this.props.show){
      return null;
    }

    const renderModal = () => {
      return (
        <div className={`modal ${this.props.size} ${this.props.scroll} ${this.props.position} ${this.props.className}`} ref={this.wrapperRef}>
            <div className={`modal__content ${this.props.fullScreen ? 'modal-fullscreen': ''} ${this.props.contentClass ? this.props.contentClass: ''}`}
              role="dialog"
              aria-modal="true"
              ref={(ref) => {this.modalRef = ref}}
              aria-hidden="false"
              tabIndex="0"
            >
              {this.props.dismissable &&
                <button className="modal__close-button" onClick={this.props.onClose}>
                  <i className="icon-close"><span className="sr-only">Close Modal</span></i>
                </button>
              }
              {this.props.children}
            </div>
          </div>
      )
    }

    return ReactDOM.createPortal(
      <div className="modal__overlay" onClick={this.handleOutsideClick}>
        {this.props.trapFocus ?
          <FocusTrap fallback = {'.modal'}>
            {renderModal()}
          </FocusTrap>
          :
          renderModal()
        }
      </div>
      ,
      this.el
    );
  }
}

Modal.size = size;
Modal.scroll = scroll;
Modal.position = position;

Modal.defaultProps = {
  show: false,
  size: Modal.size.large,
  forceFocus: false,
  dismissable: true,
  dismissableOnBlur: true,
  scroll: Modal.scroll.external,
  position: Modal.position.external,
}

ModalHeader.propTypes = {
  children: PropTypes.any.isRequired,
}
ModalTitle.propTypes = {
  children: PropTypes.any.isRequired,
}
ModalBody.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  height: PropTypes.string,
}
ModalFooter.propTypes = {
  children: PropTypes.any.isRequired,
}
Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(["modal--small", "modal--medium", "modal--large", "modal--xlarge"]),
  dismissable: PropTypes.bool,
  dismissableOnBlur: PropTypes.bool,
  forceFocus: PropTypes.bool,
  fullScreen: PropTypes.bool,
  contentClass: PropTypes.string,
}
