import React from "react";
import ListingEventCell from './ListingEventCell';
import PropTypes from 'prop-types';

const ListingEventsModal = props => {

    return (
        <div className="multiple-event-modal series-calendar-month-view__content">
            {props.listings.map((listing, index) => <ListingEventCell listing={listing} key={index} inModal={true}/>)}
        </div>
    )
}

ListingEventsModal.propTypes = {
    listings: PropTypes.array.isRequired,
}

export default ListingEventsModal;
