
const debounceLists = {};

export function debounce(func, delay, key = '0') {
    if (debounceLists[key]) {
        clearTimeout(debounceLists[key]);
    }

    debounceLists[key] = setTimeout(async () => await func(), delay);
}
