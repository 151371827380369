import React from 'react';
import classNames from 'classnames';

export const UserPhoto = ({ className, image, name, isMulti = false }) => {
    const ariaLabel = isMulti
        ? `${name || 'Multi event'}'s image or logo`
        : `${name || "The Organization"}'s user image or logo"`

    return (
        <div 
            className={classNames('org-listing__user-photo', className)}
            style={{backgroundImage: 'url(' + image + ')'}}
            role="img" 
            aria-label={ariaLabel}
        />
    );
}
