import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { toast, ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// https://fkhadra.github.io/react-toastify/introduction/

// Position
const position = {
  topLeft: 'top-left',
  topRight: 'top-right',
  topCenter: 'top-center',
  bottomLeft: 'bottom-left',
  bottomRight: 'bottom-right',
  bottomCenter: 'bottom-center',
}
// Variations
const error = ($message, $onOpen, $onClose) => toast.error(
  $message,
  {icon: <i className="icon-report toast__icon" aria-hidden="true"></i>, onOpen: $onOpen, onClose: $onClose}
);
const success = ($message, $onOpen, $onClose) => toast.success( $message,
  {icon: <i className="icon-check toast__icon" aria-hidden="true"></i>, onOpen: $onOpen, onClose: $onClose}
);
const warn = ($message, $onOpen, $onClose) => toast.warn(
  $message,
  {icon: <i className="icon-warning toast__icon" aria-hidden="true"></i>, onOpen: $onOpen, onClose: $onClose}
);
const info = ($message, $onOpen, $onClose) => toast.info(
  $message,
  {icon: <i className="icon-info_outline toast__icon" aria-hidden="true"></i>, onOpen: $onOpen, onClose: $onClose}
);

const CloseButton = ({ closeToast }) => (
  <div className="toast__action">
    <button className="toast__close-button button button--clear button--round">
      <i className="icon-close" onClick={closeToast}></i>
    </button>
  </div>
);


function Toast (props){
  return ReactDOM.createPortal(
    <ToastContainer
      className="toast"
      bodyClassName="toast__content"
      position={props.position}
      transition={Slide}
      collapseDuration={300}
      autoClose={props.autoClose}
      hideProgressBar={props.hideProgressBar}
      newestOnTop={props.newestOnTop}
      closeOnClick={props.closeOnClick}
      rtl={false}
      pauseOnFocusLoss={props.pauseOnFocusLoss}
      pauseOnHover={props.pauseOnHover}
      closeButton={CloseButton}
    />
    ,
    document.getElementById('portal-root')
  );
}


Toast.error = error;
Toast.success = success;
Toast.warn = warn;
Toast.info = info;
Toast.position = position;




Toast.propTypes = {
  position: PropTypes.oneOf(Object.values(position)),
  autoClose: PropTypes.number,
  hideProgressBar: PropTypes.bool,
  newestOnTop: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  pauseOnFocusLoss: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  closeButton: PropTypes.func,
}

Toast.defaultProps = {
  position: Toast.position.topCenter,
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: true,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
}

export default Toast;
