import React from 'react';
import PropTypes from 'prop-types';
import Color from "color-thief-react";
import classNames from 'classnames';

export const ListingListItemImage = ({ src, className: classNameProp, alt, classNamePrefix = '' }) => {
  const className = classNames(
    `${classNamePrefix}listing-item__hero__container`,
    classNameProp
  )

  return (
    <>
      <Color src={src} crossOrigin="anonymous" format="hex">
        {({ data, loading }) => {
          return (
            // TODO: Replace loading gray color with Org Listing primary color
            <div
              className={className}
              style={{
                backgroundColor: loading
                  ? 'rgb(151, 151, 151)'
                  : data
              }}
            >
              {loading ? (
                <div className="loading-spinner">
                  <i className="icon-spinner" aria-hidden="true"></i>
                </div>
              ) : (
                <img className={classNamePrefix + "listing-item__image"} src={src} alt={alt} />
              )}
            </div>
          );
        }}
      </Color>
    </>
  );
}

ListingListItemImage.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    classNamePrefix: PropTypes.string
}
