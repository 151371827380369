import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import FacebookIcon from "../utils/icons/FacebookIcon";
import InstagramIcon from "../utils/icons/InstagramIcon";
import TwitterIcon from "../utils/icons/TwitterIcon";
import {AppContext} from "../../App";

const ListingFooter = (props) => {
    const { t } = useContext(AppContext);

    const onSocialMediaClick = (link) => {
        window.open(link, '_blank');
    }

    const onInstagramClick = () => {
        onSocialMediaClick(props.socialMedia.instagram.link)
    }

    const onTwitterClick = () => {
        onSocialMediaClick(props.socialMedia.twitter.link)
    }

    const onFacebookClick = () => {
        onSocialMediaClick(props.socialMedia.facebook.link)
    }

    return (
        <div className={'listing-footer ' + props.className}>
            <div>
                <p className='text-small bold'>{props.organizationInfo?.name}</p>
                {props.organizationInfo?.address && <p className='text-small'>
                    {props.organizationInfo.address}, {props.organizationInfo?.city}, {props.organizationInfo?.state}
                </p>}
                {props?.organizationInfo?.phone && <p className='text-small'>{props.organizationInfo.phone}</p>}

                <div className='d-inline-block text-small mt-2'>
                    {props.organizationInfo?.website &&
                        <a target='_blank' className='mr-4' href={props.organizationInfo.website}>
                            {t('org_listing__view_website')}
                        </a>}
                    {props.organizationInfo?.contactUs &&
                        <a target='_blank' href={props.organizationInfo.contactUs}>
                            {t('org_listing__contact_us')}
                        </a>}
                </div>

            </div>

            <div>
                <div className='listing-icons'>
                    {props.socialMedia.twitter &&
                        <TwitterIcon onClick={onTwitterClick}/>
                    }

                    {props.socialMedia.instagram &&
                        <InstagramIcon onClick={onInstagramClick}/>
                    }

                    {props.socialMedia.facebook &&
                        <FacebookIcon onClick={onFacebookClick}/>
                    }
                </div>
            </div>
        </div>
    );
}

ListingFooter.propTypes = {
    className: PropTypes.string,
    organizationInfo: PropTypes.object.isRequired,
    socialMedia: PropTypes.object.isRequired,
}

ListingFooter.defaultProps = {
    className: '',
}

export default ListingFooter;
