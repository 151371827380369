import { useCallback } from 'react';

const isValidLocale = (locale) => {
  return /^[a-z]{2}(-[A-Z]{2})?$/i.test(locale);
};

export const useTranslationService = (translations, prefix = 'org_listing') => {
  const t = useCallback((key, variables = {}) => {
    const vars = Object.keys(variables);
    
    const possibleKey = key.startsWith(prefix) ? key : `${prefix}__${key}`;

    let text = translations[possibleKey] || possibleKey.toString().toLocaleUpperCase();

    if(vars.length) {
        vars.foreach((variableName) => {
            text = text.replace('%' + variableName + '%', variables[variableName]);
        })
    }

    return text;
  }, [translations, prefix]);

  return {
    t,
    isValidLocale,
  }
}
