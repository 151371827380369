import React from 'react'
import { render } from 'react-dom'
import App from './App';

export default (domElement, configs, sellerInfo, listings) => {
    render(
        <App
            configs={configs}
            sellerInfo={sellerInfo}
            listings={listings}
        />,
        domElement
    )
}
