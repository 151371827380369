import React from 'react';
import PropTypes from "prop-types";

const Loader = (props) => {
    if(props.loaded) {
        return props.children;
    }

    return (
        <div className='loader-wrapper' style={{height: props.height}}>
            <div className='loader' />
        </div>
    )
}

Loader.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
    loaded: PropTypes.bool.isRequired,
}

Loader.defaultProps = {
    className: '',
    height: 'auto'
}

export default Loader;
