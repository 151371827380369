import PropTypes from 'prop-types';
import React from 'react';
import { datesService } from '../../services/DatesService';
import Loader from "../utils/Loader";

const ListingToolbar = (props) => {
    const { toolbarDefaultDateFormat } = datesService();

    return (
        <div className="org-listing-calendar__toolbar">
            <div className="org-listing__toolbar__navigation">
                <button className="button" onClick={props.onPrevious} disabled={!!props.loading || !!props.previousDisabled}>
                    <span className="sr-only">Previous Month</span>
                    <i className="icon-navigate_before" aria-hidden="true"></i>
                </button>

                <span className="org-listing__toolbar__month">
                    {toolbarDefaultDateFormat(props.date)}
                </span>

                <button className="button" onClick={props.onNext} disabled={!!props.loading || !!props.nextDisabled}>
                    <span className="sr-only">Next Month</span>
                    <i className="icon-navigate_next" aria-hidden="true"></i>
                </button>
            </div>

            {props.loading && <Loader loaded={props.loading} />}
        </div>
    );
}

ListingToolbar.propTypes = {
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date),
    loading: PropTypes.bool,
    previousDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool
};

export default ListingToolbar;
