import React from 'react';
import classNames from 'classnames';

export const Header = ({ info, logo, className, children, isMulti = false }) => {
  const alt = isMulti ? `${info?.name ?? ''} Logo` : `${info?.name ?? ''} Organization Logo`;

  return (
    <div className={classNames('org-listing__header', className)}>
      <img className="org-listing__header__image" src={logo} alt={alt} />
      {children}
    </div>
  );
};
