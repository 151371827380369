import { httpService } from "./HttpService";

export function listingService (apiUrl) {
    const { get, buildQueryParams } = httpService();

    const getUpcomingListings = async (sellerShortName, {name = null}, abortSignal) => {
        const queryParams = buildQueryParams({
            name,
        });

        try {
            const response = await fetch(`${apiUrl}/organization-listing/${sellerShortName}/upcoming${queryParams}`, {
                signal: abortSignal
            })

            if(!response.ok) {
                throw new Error(await response.text());
            }

            return (await response.json());
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    const getRangeListings = async (sellerShortName, start = null, end = null) => {
        const queryParams = buildQueryParams({
            start,
            end,
        });

        try {
            const response = await get(`${apiUrl}/organization-listing/${sellerShortName}/range${queryParams}`);

            if(!response.ok) {
                throw new Error(await response.text());
            }

            return (await response.json());
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    const getPastListings = async (sellerShortName, limit) => {
        const queryParams = buildQueryParams({
            limit
        });

        try {
            const response = await get(`${apiUrl}/organization-listing/${sellerShortName}/past${queryParams}`);

            if(!response.ok) {
                throw new Error(await response.text());
            }

            return (await response.json());
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    return {
        getUpcomingListings,
        getPastListings,
        getRangeListings
    }
}
