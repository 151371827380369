import React, { useState, } from 'react';
import PropTypes from 'prop-types';
import Toast from '@showclix-shared/Toast';
import {
    ListingAppContext,
    Header,
    Footer,
    useTranslationService
} from '@showclix-shared/ListingComponents';
import ListingView from "./views/ListingView";
import {datesService} from "./services/DatesService";

export const AppContext = ListingAppContext;

const App = (props) => {
    const [apiUrl, setApiUrl] = useState(props.configs.apiUrl);
    const [sellerShortName, setSellerShortName] = useState(props.sellerInfo.shortName);
    const { t, isValidLocale } = useTranslationService(props.configs.translations);

    datesService().useLocale(props.configs.jsLocale);

    const appContextData = () => ({
        apiUrl,
        setApiUrl,
        sellerShortName,
        setSellerShortName,
        t,
        locale: isValidLocale(props.configs.jsLocale) ? props.configs.jsLocale : 'en-US',
        serverLocale: props.configs.locale,
        serverTimezoneName: props.configs.serverTimezoneName
    })

    return (
        <AppContext.Provider value={ appContextData() }>
            <Header logo={props.sellerInfo.partnerLogo} />
            <ListingView
                listings={props.listings ?? []}
                userPhoto={props.sellerInfo.userImage}
                organizationInfo={props.sellerInfo.organizationInfo}
                socialMedia={props.sellerInfo.social}
                backgroundImage={props.sellerInfo.backgroundImage}
                description={props.sellerInfo.description}
            />

            <Footer
                logo={props.sellerInfo.partnerLogo}
                copyrightName={props.sellerInfo.copyrightName ?? ''}
                contactUrl={props.sellerInfo?.contact_url ?? ''}
                tosUrl={props.sellerInfo?.tos_url ?? ''}
                privacyUrl={props.sellerInfo?.privacy_url ?? ''}
            />
            <Toast />
        </AppContext.Provider>
    );
}

App.propTypes = {
    sellerInfo: PropTypes.object.isRequired,
    listings: PropTypes.array,
    configs: PropTypes.object.isRequired,
}

export default App;
