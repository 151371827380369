import React, {useContext} from 'react';
import classNames from 'classnames';
import { ListingAppContext } from '../contexts';

export const Footer = ({
    contactUrl,
    tosUrl,
    privacyUrl,
    logo,
    copyrightName,
    messages,
    className
}) => {
    const { t } = useContext(ListingAppContext);
    const year = new Date().getFullYear();

    return (
        <div className={classNames('footer', className)}>
            <div className='footer-container org-listing__container'>
                <div className='footer-main'>
                    <div>
                        <ul className='footer-links clean'>
                            {!!contactUrl && (
                                <li><a href={contactUrl} target="_blank">{t('support')}</a></li>
                            )}
                            {!!tosUrl && (
                                <li><a href={tosUrl} target="_blank">{t('terms_of_service')}</a></li>
                            )}
                            {!!privacyUrl && (
                                <li><a href={privacyUrl} target="_blank">{t('privacy_policy')}</a></li>
                            )}
                        </ul>
                        <p className='footer-copyright'>©{year} {copyrightName}</p>
                    </div>

                    <div>
                        <img src={logo} className='footer-logo' alt={`${copyrightName} Logo`}/>
                    </div>
                </div>
                <p className='footer-copyright--mobile'>©{year} {copyrightName}</p>
                {messages}
            </div>
        </div>
    );
}
