import React from 'react';

export const BackgroundImage = (props) => {
    return (
        <div className='org-listing__hero'>
            {props.image &&
                <div
                    className='org-listing__hero__image'
                    style={{ backgroundImage: 'url('+props.image+')'}}
                    role="img" 
                    aria-label={`${props.organizationName || "The Organization"}'s background image"`}
                />
            }
        </div>
    );
}
