import React, {useContext} from "react";
import ListingListItem, {ListingListItemSkeleton} from "./ListingListItem";
import PropTypes from "prop-types";
import {AppContext} from "../../App";

const ListingList = (props) => {
    const { t } = useContext(AppContext)

    if (props.listings.length < 1) {
        return (
            <div className='listing-no-items'>
                {
                    props.filterStr
                        ? <React.Fragment>{ t('org_listing__result_not_found', {filter: props.filterStr})} <br/> {t('org_listing__try_another_search') }</React.Fragment>
                        : (props.noItemsText || t('org_listing__no_upcoming_events'))
                }
            </div>
        )
    }

    return (
        <div className={'listing-items__wrapper ' + props.className}>
            {props.listings.map(
                (listing, index) => <ListingListItem listing={listing} key={index} decodeTitle={!props.filter}/>
            )}
        </div>
    );
}

ListingList.propTypes = {
    listings: PropTypes.array.isRequired,
    filter: PropTypes.string,
    className: PropTypes.string,
    noItemsText: PropTypes.string,
}

ListingList.defaultProps = {
    noItemsText: '',
    className: '',
    filter: '',
}

export const ListingListSkeleton = () => {
    return (
        <div className='listing-items__wrapper listing-items__wrapper-skeleton'>
            <ListingListItemSkeleton />
            <ListingListItemSkeleton />
            <ListingListItemSkeleton />
            <div className="listing-items__wrapper-skeleton__last-item">
                <ListingListItemSkeleton />
            </div>
        </div>
    );
}

export default ListingList
