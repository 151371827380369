import React, { useState, useContext, Fragment, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {AppContext} from "../App";
import ListingSearch from "../components/listing/ListingSearch";
import CalendarIcon from "../components/utils/icons/CalendarIcon";
import GridViewIcon from "../components/utils/icons/GridViewIcon";
import ListingFooter from "../components/listing/ListingFooter";
import ListingList, { ListingListSkeleton } from "../components/listing/ListingList";
import { listingService } from "../services/ListingService";
import { debounce } from "../utils/functions"
// import Expander from "../components/utils/Expander";
// import Loader from "../components/utils/Loader";
import ListingCalendar from "../components/listing/ListingCalendar";
// import Button from "@showclix-shared/Button"
import Toast from "@showclix-shared/Toast"
import { BackgroundImage, UserPhoto, ListingHeading } from "@showclix-shared/ListingComponents";
import {useDebounce} from "../hooks/use-debounce";

const GRID_VIEW = 'grid-view';
const CALENDAR_VIEW = 'calendar';
// const PAST_LISTING_LIMIT = 5;

const ListingView = (props) => {
    let date = (new URL(location.href))?.searchParams?.get("month")
    const [tab, setTab] = useState(date ? CALENDAR_VIEW : GRID_VIEW);
    const [filter, setFilter] = useState(undefined);
    const filterDebounced = useDebounce(filter);

    const [listings, setListings] = useState(props.listings);
    const [listingsLoading, setListingsLoading] = useState(false);

    // Shouldn’t be shown in Phase 1
    // const [pastListings, setPastListings] = useState([]);
    // const [canLoadMore, setCanLoadMore] = useState(false);
    // const [pastListingLimit, setPastListingLimit] = useState(PAST_LISTING_LIMIT)
    // const [pastListingLoaded, setPastListingLoaded] = useState(false);

    const { apiUrl, sellerShortName, t } = useContext(AppContext);

    // Shouldn’t be shown in Phase 1
    // getPastListings
    const { getUpcomingListings } = useMemo(
        () => listingService(apiUrl),
        [apiUrl]
    );

    useEffect(() => {
        if (filterDebounced !== undefined) {
            const abortController = new AbortController()

            setListingsLoading(true);

            getUpcomingListings(
                sellerShortName,
                {
                    name: filterDebounced
                },
                abortController.signal
            )
                .then(({ listings }) => {
                    setListings(listings ?? [])
                    setListingsLoading(false)
                })
                .catch((e) => {
                    const isAbortError = e.name === 'AbortError'
                    if (!isAbortError) {
                        Toast.error(<>An error occurred during search for <b>{filterDebounced}</b>. Please, try again.</>);
                        setListings([])
                        setListingsLoading(false)
                    }
                });

            return () => {
                abortController.abort()
            }
        }
    }, [sellerShortName, filterDebounced, setListings, setListingsLoading, getUpcomingListings])

    const onClickIcon = (icon) => {
        // strip month from url
        if(icon === GRID_VIEW) {
            const url = new URL(location.href);
            window.history.pushState('', '', url.pathname);
        }
        setTab(icon);
    }

    const isIconSelected = (icon) => tab === icon;

    const onChangeFilter = (e) => {
        onClickIcon(GRID_VIEW);
        setFilter(e.target.value);

        debounce(async () => {
            const {listings} = await getUpcomingListings(sellerShortName, {
                name: e.target.value
            });

            setListings(listings ?? []);
        }, 300);
    }

    // Shouldn’t be shown in Phase 1
    // const onClosePastListings = async () => {
    //     setPastListingLimit(PAST_LISTING_LIMIT);
    //     setPastListings([]);
    // }

    // const onOpenPastListings = async () => {
    //     setPastListingLimit(PAST_LISTING_LIMIT);

    //     await resetPastListings(pastListingLimit);
    // }

    // const onClickLoadMore = async () => {
    //     if (pastListingLoaded) {
    //         const limit = pastListingLimit + PAST_LISTING_LIMIT;
    //         setPastListingLimit(limit);

    //         await resetPastListings(limit);
    //     }
    // }

    // const resetPastListings = async (limit) => {
    //     setPastListingLoaded(false);
    //     try {
    //         const { pastListings, hasMore } = await getPastListings(sellerShortName, limit);

    //         setPastListings(pastListings);
    //         setCanLoadMore(hasMore);
    //     } catch (e) {
    //         Toast.error("An error occurred when loading past events. Please, try again.");
    //         setPastListings([]);
    //         setCanLoadMore(false);
    //     } finally {
    //         setPastListingLoaded(true);
    //     }
    // }

    return (
        <>
            <BackgroundImage
                image={props.backgroundImage}
            />
            <div className='org-listing__main'>
                <div className='org-listing__main__container org-listing__container'>
                    {props.userPhoto &&
                        <UserPhoto
                            className='listing__user-photo'
                            image={props.userPhoto}
                            name={props.organizationInfo.name}
                        />
                    }
                    <ListingHeading
                        primaryText={props.organizationInfo.name}
                        secondaryText={props.description}
                    />
                    <h2 className='org-listing__upcoming-title'>Upcoming Events</h2>

                    <div className='listing-items__tools'>
                        <ListingSearch
                            className='listing-search'
                            onChange={onChangeFilter}
                        />
                        <GridViewIcon
                            selected={isIconSelected(GRID_VIEW)}
                            onClick={() => onClickIcon(GRID_VIEW)}
                        />

                        <CalendarIcon
                            selected={isIconSelected(CALENDAR_VIEW)}
                            onClick={() => onClickIcon(CALENDAR_VIEW)}
                        />
                    </div>
                    { isIconSelected(GRID_VIEW) ? (
                        <Fragment>
                            {listingsLoading
                                ? <ListingListSkeleton/>
                                : <ListingList
                                    listings={listings ?? []}
                                    filter={filter ?? ''}
                                    noItemsText={t('org_listing__no_upcoming_events')}
                                />
                            }
                            {/* Shouldn’t be shown in Phase 1 */}
                            {/* <Expander
                                className='mt-4 mb-4'
                                title={t('org_listing__past_events')}
                                onOpen={onOpenPastListings}
                                onClose={onClosePastListings}
                            >
                                {pastListingLoaded
                                    ? <>
                                        <ListingList noItemsText={t('org_listing__no_past_events_were_found')} listings={pastListings}/>
                                        {canLoadMore &&
                                            <div className='listing__load-more'>
                                                <Button variant='default' onClick={onClickLoadMore}>{t("org_listing__load_more")}</Button>
                                            </div>
                                        }
                                    </>
                                    : <ListingListSkeleton/>
                                }
                            </Expander> */}
                        </Fragment>
                    ) : (
                        <ListingCalendar />
                    )}

                    {props?.organizationInfo &&
                        <ListingFooter
                            className='mt-4'
                            organizationInfo={props.organizationInfo}
                            socialMedia={props.socialMedia}
                        />
                    }
                </div>
            </div>

        </>
    );
}

ListingView.propTypes = {
    listings: PropTypes.array.isRequired,
    userPhoto: PropTypes.string,
    backgroundImage: PropTypes.string,
    description: PropTypes.string,
    organizationInfo: PropTypes.object,
    socialMedia: PropTypes.object,
}

export default ListingView;
